import React from 'react';
import StatusScreen from '../StatusScreen';
import { withRouter } from "react-router-dom";
import authClient from "../Auth";
import { getUserProfile } from "../api";

const OK_BLUE = 'ok-blue';
const OK_RED = 'ok-red';
const OK_GREEN = 'ok-green';

const statusToStyles = {
    'ok-blue': 'okBlue',
    'ok-red': 'okRed',
    'ok-green': 'okGreen'
}

const enabledStatus = [OK_RED, OK_BLUE, OK_GREEN];

class PaymentStatus extends React.Component {

    constructor(props) {
        super();
        this.status = props.match.params.status;
        this.status = this.status === 'ok' ? OK_BLUE : this.status;
    }

    componentWillMount() {
        getUserProfile()
        .then((profile) => authClient.updateProfile(profile))
        .catch((error) => console.log(error));
    }

    render() {
        let title;
        let content;
        let statusStyle;
        if (enabledStatus.includes(this.status)) {
            title = '¡Felicitaciones!';
            content = <p className="status-screen-emphasis">
                Pago aceptado con éxito
            </p>;
            statusStyle = statusToStyles[this.status];
            console.log(this.status, statusToStyles, statusStyle);
        } else {
            title = 'ERROR';
            content = <p className="status-screen-emphasis">
                Su pago no se ha podido procesar. Intente nuevamente en unos minutos.
            </p>;
            statusStyle = 'error';
        }
        return <StatusScreen title={title} status={statusStyle} content={content} onRedirect={this.props.handleDisplayStatusTimeout} />;
    }

}

export default withRouter(PaymentStatus);