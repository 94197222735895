import React from "react";
import DashboardMenu from "../DashboardMenu";
import "./DashboardNavigation.css";
import iconCamera from "../../ProfilePicture/img/camera-upload.svg";

function DashboardProfilePicture({ userProfileURL }) {
    return (<div>
        <img className="dashboard-profile-pic" src={userProfileURL} alt="Usuario"/>
    </div>);
}

class DashboardNavigation extends React.Component {

    render() {
        return (
            <div className="dashboard-navigation">
                <DashboardMenu dashboard={this.props.dashboard} />
                {this.props.title && <div className="dashboard-navigation-title">
                    {this.props.title}
                </div> 
                }
                {
                    this.props.userProfileURL &&
                    <DashboardProfilePicture userProfileURL={this.props.userProfileURL}  />
                }
                {
                    !this.props.hideProfileIcon && !this.props.userProfileURL &&
                    <div className="empty-dashboard-profile-pic">
                        <img alt='Perfil' src={iconCamera}/>
                    </div>
                }
            </div>
        );
    }
}

export default DashboardNavigation;