import React from "react";
import DashboardNavigation from "../Dashboard/DashboardNavigation";
import "./Notifications.css";
import { getNotifications, markNotificationAsRead } from "../api";
import checkmarkRead from "./img/checkmark-read.svg";
import checkmarkUnread from "./img/checkmark-unread.svg";
import authClient from "../Auth";

class Notification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notification: this.props.notification || {}
        }
        this.handleMarkRead = this.handleMarkRead.bind(this);
    }

    handleMarkRead() {
        if (this.state.notification.read) { return; }
        console.log(this.state.notification.id);
        var notification = this.state.notification;
        notification.read = true;
        this.setState({ notification: notification});
        markNotificationAsRead(notification.id)
        .then((response) => authClient.updateProfile(response.profile))
        .catch((error) => { 
            console.log(error) 
        });
    }

    render() {
        const notification = this.state.notification;
        return (
            <div className="main-notification-container">
                <div className="notification-text-container">
                    <div className="notification-title">{notification.title}</div>
                    <div className="notification-subtitle">{notification.subtitle}</div>
                </div>
                <div className={`notification-checkmark ${notification.read ? `notification-read` : `notification-unread`}`} onClick={this.handleMarkRead}>
                    <img src={notification.read ? checkmarkRead : checkmarkUnread } alt="Notificación"/>
                </div>
            </div>
        );
    }
}

class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.fetchNotifications = this.fetchNotifications.bind(this);
        this.state = {
            notifications: []
        }
    }

    fetchNotifications() {
        getNotifications().then(response => {
            console.log(response);
            this.setState({ notifications: response.notifications });
        });
    }

    componentDidMount() {
        this.fetchNotifications();
    }

    render() {
        return (
            <div className="notifications-main-container">
                <div className="appointments-app notifications-app">
                    <DashboardNavigation userProfileURL={this.props.userProfileURL} dashboard={this.props.dashboard} title='Notificaciones' />
                    <div className="notifications-container">
                        {
                            this.state.notifications.map((notification) => (
                                <Notification key={notification.id} notification={notification} />
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Notifications;