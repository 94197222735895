import React from "react";
import { withRouter } from "react-router-dom";
import AppointmentDay from "./AppointmentDay";
import { toISODateString } from "../../Profile";

const daysOfWeek = ["DOM", "LUN", "MAR", "MIER", "JUEV", "VIER", "SAB"];

class CalendarSelection extends React.Component {

    constructor(props) {
        super(props);
        this.buildDays = this.buildDays.bind(this);
        this.handleSelectDay = this.handleSelectDay.bind(this);
    }

    getDaysInMonth(year, currentMonth) {
        var dayOfMonth = new Date(year, currentMonth, 1);
        var month = currentMonth;
        var daysInMonth = [];
        while (month === currentMonth) {
            daysInMonth.push(dayOfMonth.getDate());
            var tempDate = dayOfMonth;
            tempDate.setDate(dayOfMonth.getDate() + 1);
            dayOfMonth = tempDate;
            month = dayOfMonth.getMonth();
        }
        return daysInMonth;
    }
    getDaysOfPreviousMonth(year, month, dayOfWeekOfFirstDayInMonth) {
        var day = new Date(year, month, 1);
        var daysOfPreviousMonth = [];
        for (var i = 0; i < dayOfWeekOfFirstDayInMonth; i++) {
            var tempDate = day;
            tempDate.setDate(day.getDate() - 1);
            day = tempDate;
            daysOfPreviousMonth.unshift(day.getDate());
        }
        return daysOfPreviousMonth;
    }

    getDaysOfNextMonth(lastDayInMonth, numberOfRemainingDays) {
        var day = lastDayInMonth;
        var daysOfNextMonth = [];
        for (var i = 0; i < numberOfRemainingDays; i++) {
            var tempDate = day;
            tempDate.setDate(day.getDate() + 1);
            day = tempDate;
            daysOfNextMonth.push(day.getDate());
        }
        return daysOfNextMonth;
    }

    getDayStatus(year, month, day) {
        if (day === this.props.selectedDay) {
            return AppointmentDay.SELECTED;
        }

        const date = toISODateString(year, month + 1, day);

        if (this.props.availability && this.props.availability[date]) {
            return AppointmentDay.AVAILABLE;
        }

        return AppointmentDay.NOT_AVAILABLE;
    }

    buildDays() {
        const year = this.props.year;
        const month = this.props.month;

        const daysInMonth = this.getDaysInMonth(year, month);

        const dayOfWeekOfFirstDayInMonth = new Date(year, month, 1).getDay();
        const daysOfPreviousMonth = this.getDaysOfPreviousMonth(year, month, dayOfWeekOfFirstDayInMonth);

        const partialCalendarDays = daysOfPreviousMonth.concat(daysInMonth);
        const numberOfRemainingDays = 42 - partialCalendarDays.length;

        const lastDayInMonth = new Date(year, month, daysInMonth[daysInMonth.length - 1]);
        const daysOfNextMonth = this.getDaysOfNextMonth(lastDayInMonth, numberOfRemainingDays);

        const days = partialCalendarDays.concat(daysOfNextMonth);

        var daysStatus = daysOfPreviousMonth.map(() => AppointmentDay.DISABLED);
        daysStatus = daysStatus.concat(daysInMonth.map((day) => this.getDayStatus(year, month, day)));
        daysStatus = daysStatus.concat(daysOfNextMonth.map(() => AppointmentDay.DISABLED));

        return {
            days: days,
            daysStatus: daysStatus
        };
    }

    handleSelectDay(day) {
        this.props.onDaySelected && this.props.onDaySelected(day);
    }

    render() {
        const { days, daysStatus } = this.buildDays();
        return(
            <div className="appointments-calendar-container">
                <div className="appointments-calendar">
                    {
                        daysOfWeek.map((day) => (
                            <AppointmentDay key={day} day={day} status={AppointmentDay.WEEK_DAY} />
                        ))
                    }
                    {days.map((day, index) => (

                        <AppointmentDay key={index} day={day} status={daysStatus[index]} onDaySelected={this.handleSelectDay} />
                    ))}
                </div>
            </div>
        );
    }

}

export default withRouter(CalendarSelection);