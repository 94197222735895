import React from 'react';
import './Forms.css';

export function ErrorMessagesList({ errors }) {
    return (
        errors.length > 0 ?
            <ul className="input-error">
                {
                    errors.map((error) => <li key={error}>{error}</li>)
                }
            </ul>
            : null
    );
}

export class Loading extends React.Component {
    constructor() {
        super();

        this.state = { dots: "." };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                dots: this.state.dots.length === 3 ? "" : this.state.dots + "."
            });
        }, 500);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="loading">
                Cargando{this.state.dots}
            </div>
        );
    }
}

export function CustomInput(props) {
    return (
        <div>
            {props.children}
            {
                props.messages ?
                    <ErrorMessagesList errors={props.messages} />
                    : null
            }
        </div>
    );
}