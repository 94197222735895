import React from "react";
import GradientButton from "../GradientButton";
import { Redirect } from "react-router-dom";

class MercadoPago extends React.Component {

    constructor() {
        super();
        this.handleAccept = this.handleAccept.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.state = {
            accepted: false,
            canceled: false
        }
    }

    handleAccept() {
        this.setState({ accepted: true });
    }

    handleCancel() {
        this.setState({ canceled: true });
    }

    render() {

        if (this.state.accepted) {
            const paymentOkCallback = sessionStorage.getItem('paymentCallback') || "/payment/ok";
            sessionStorage.removeItem('paymentCallback');
            return <Redirect to={paymentOkCallback} />
        }

        if (this.state.canceled) {
            return <Redirect to="/payment/error"/>
        }

        return (
            <div>
                <h1>Pagar con MercadoPago</h1>
                <GradientButton onClick={this.handleAccept} text="Pagar" className="button-green-gradient" />
                <GradientButton onClick={this.handleCancel} text="Cancelar" className="button-red-gradient" />
            </div>
        );
    }
}

export default MercadoPago;