import React from "react";
import "./Availability.css";
import selectedAppointment from "./img/selected-appointment.svg";
import availableAppointment from "./img/available-appointment.svg";

class AppointmentAvailabilityList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null
        }
        this.handleSelectedChange = this.handleSelectedChange.bind(this);
    }

    handleSelectedChange(optionId) {
        this.setState((state) => ({ selectedOption: optionId }));
        this.props.onSelect(optionId);
    }

    render() {
        const availability = this.props.availability;
        return (
            <div className="appointment-availability">

                {availability.map((item) => (
                    <div key={item.id} className="appointment-availability-item">
                        <div className="appointment-availability-item-hour-container">
                            <div className="appointment-availability-item-hour">
                                {item.hour}
                            </div>
                            <div className="appointment-availability-item-period">
                                {item.period}
                            </div>
                        </div>

                        <div className="appointment-availability-item-location-mode-container">
                            <div className="appointment-availability-item-title">
                                {item.nutritionist}
                            </div>
                        </div>
                        <div className="appointment-availability-item-selection" onClick={() => this.handleSelectedChange(item.id)}>
                            <img src={this.state.selectedOption === item.id ? selectedAppointment : availableAppointment} alt="Turno" />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default AppointmentAvailabilityList;