import React from "react";
import Popup from "../Popup";
import { LegalTextScroll } from "../Payment/LegalWarning";
import { CloseableWarning } from "../Appointments/Warning";

class LandingTerms extends React.Component {

    render() {
        return (
            <Popup>
                <CloseableWarning onCancel={() => this.props.onCancel()} className="appointments-warning-sized">
                    <LegalTextScroll />
                </CloseableWarning>
            </Popup>
        );
    }
}

export default LandingTerms;