import React from "react";
import "./DashboardMenu.css";
import navbarIcon from "./img/sidebar-mobile-light.svg";
import SidebarMenu from "../../SidebarMenu";
import { withRouter } from 'react-router-dom';
import authClient from '../../Auth';
import { Link } from "react-router-dom";
import Badge from "./Badge";
import { AboutContainer, ABOUT_TYPES } from "../../Common";

class DashboardMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleClickLogout = this.handleClickLogout.bind(this);
    }

    handleClickLogout() {
        console.log('Should log out user...');
        authClient.signOut();
        window.location = '/';
    }

    toggleMenu() {
        this.setState((state) => ({ showMenu: !state.showMenu }));
    }

    render() {
        var notificationsCount = authClient.getUserData().profile.notifications_count || 0;
        notificationsCount = notificationsCount > 99 ? '99+' : notificationsCount;
        return (
            <div>
                <img className="dashboard-menu-icon" src={navbarIcon} alt="Menu" onClick={this.toggleMenu} />
                {this.state.showMenu &&
                    <SidebarMenu onClose={this.toggleMenu}>
                        {
                            this.props.dashboard.sections.map((item) => (
                                <div className="sidebar-item" key={item.title}>
                                    <Link to={item.path ? `/app/${item.path}` : '#'}>
                                        {item.title}
                                    </Link>
                                    { item.path === 'notificaciones' && notificationsCount > 0 && <Badge count={notificationsCount}/> }
                                </div>
                            ))
                        }
                        <div className="sidebar-item">
                            <AboutContainer aboutType={ABOUT_TYPES.GREY}/>
                        </div>
                        <div className="sidebar-item-strong dashboard-menu-logout" onClick={this.handleClickLogout}>
                            Cerrar sesión
                        </div>
                    </SidebarMenu>

                }
            </div>
        );
    }
}

export default withRouter(DashboardMenu);