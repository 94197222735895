import React from 'react';
import './UnderageDisclaimer.css';
import redWarning from '../../images/redWarning.svg';

class UnderageDisclaimer extends React.Component {

    constructor(props) {
        super(props);
        this.timeoutInMillis = this.props.timeoutInMillis || 4000;
    }

    componentDidMount() {
        this.props.onTimeout && setTimeout(() => {
            this.props.onTimeout();
        }, this.timeoutInMillis);
    }

    render() {
        return (
            <div className="underage-container">
                <div className="underage-items">
                    <img src={redWarning} alt='Menores de edad necesitan autorización de padre/madre o tutor para realizar el tratamiento.' />
                    <p>Menores de edad necesitan autorización de padre/madre o tutor para realizar el tratamiento. 
                        Escribinos a <a href="mailto:info@menteycuerpo.com.ar">info@menteycuerpo.com.ar</a></p>
                </div>
            </div>
        );
    }

}

export default UnderageDisclaimer;