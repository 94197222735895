import React from "react";
import "./ProfilePicture.css";
import iconCamera from "./img/camera-upload.svg";
import editProfilePhotoIcon from './img/edit-profile-photo.svg';
import editProfilePhotoIconRed from './img/edit-profile-photo-red.svg';
import GradientButton from "../GradientButton";
import authClient from "../Auth";

class ProfilePicture extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imagePreview: props.userProfileURL
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        if (!e.target.value) {
            return;
        }
        const filePath = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ imagePreview: reader.result });
            this.props.onProfilePictureReadEnd && this.props.onProfilePictureReadEnd(reader.result);
        };
        reader.readAsDataURL(filePath);
    }

    render() {
        const profilePictureName = "profilePicture";
        const photoIcon = this.props.theme ? editProfilePhotoIconRed : editProfilePhotoIcon;
        return (
                <div className="camera-container">
                    <input type="file" accept="image/png, image/jpeg" name={profilePictureName} id={profilePictureName} onChange={this.handleInputChange}/>

                    <label htmlFor={profilePictureName}>
                        { !this.state.imagePreview && <img className="profile-picture" src={iconCamera} alt="Seleccione una imagen" /> }
                        { this.state.imagePreview && 
                            <div className="edit-picture-container">
                                <img className="profile-picture" src={this.state.imagePreview} alt="" />
                                <img className="edit-picture-icon" src={photoIcon} alt="Cambiar foto" />
                            </div>
                        }
                    </label>
                </div>
        );
    }
}

export class ProfilePictureDemo extends React.Component {

    constructor() {
        super();
        this.user = authClient.getUserData();
        this.handleClick = this.handleClick.bind(this);
        this.handlePicChanged = this.handlePicChanged.bind(this);
        const userProfileURL = this.user && this.user.profile.picture && `${process.env.REACT_APP_API_HOST}${this.user.profile.picture}`;
        this.state = {
            userProfileURL: userProfileURL,
            userProfilePicture: null
        }
    }

    handlePicChanged(pic) { 
        console.log('Save:', pic.length);
        this.setState( { userProfilePicture: pic } );
    }

    handleClick() {
        console.log('Save profile, should perform request...');
        if (!this.userProfilePicture) { return; }
    }

    render() {
        
        return (
            <div className="profile-picture-container">
                <h1>Editar foto de perfil...</h1>
                <ProfilePicture userProfileURL={this.state.userProfileURL} onProfilePictureReadEnd={this.handlePicChanged} />
                
                <h3>Subir imagen!</h3>
                    
                { this.state.userProfilePicture && <GradientButton onClick={this.handleClick} text="Guardar" /> }

            </div>
        );
    }

}

export default ProfilePicture;