import React from 'react';
import './ForgotPassword.css';
import GradientButton from '../GradientButton';
import { requestPasswordChange } from '../api';
import LoadingPopup from '../Common/LoadingPopup';

export default class ForgotPassword extends React.Component {

    constructor() {
        super();
        this.state = {
            email: ''
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOnChange(event) {
        const target = event.target;
        this.setState({ [target.name]: target.value });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        this.setState({ loading: true }, () => {
            console.log(this.state.email);
            requestPasswordChange(this.state.email)
            .then((response) => {
                this.setState({ message: response.message, email: '', loading: false });
            })
            .catch((error) => {
                var message = '';
                if (error.message) {
                    message = error.message;
                } else if (error.errors && error.errors['email']) {
                    message = error.errors['email'];
                }
                this.setState({ message: message, email: '', loading: false });
            });
        });
    }

    render() {
        return (<div className="forgot-password-container">

            <div className="forgot-password-container-title">
                Ingrese su email
            </div>
            <div className="forgot-password-container-subtitle">
                Enviaremos un correo a la cuenta para que pueda acceder a su usuario
            </div>
            <form onSubmit={this.handleSubmit}>
                <div className="inputs-group-container forgot-password-form" >
                    <div className="inputs-group inputs-group-center">
                        <div className="icon-input-container">
                            <div className="input-container">
                                <input type="email" onChange={this.handleOnChange} value={this.state.email} name="email" required />
                                <div className="profile-input-description">Email</div>
                            </div>
                        </div>
                    </div>
                </div>
                <GradientButton type="submit" text="Restablecer contraseña" />
                { this.state.loading && <LoadingPopup />}
                {this.state.message && <div className="forgot-password-message">{this.state.message}</div>}
            </form>
        </div>);
    }

}

