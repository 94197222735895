import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationHeader.css';
import loginIcon from './img/login-icon.svg';
import landingSidebarIcon from './img/landing-sidebar-icon.svg';
import MediaQuery from 'react-responsive';
import SidebarMenu from '../../SidebarMenu';
import { SocialLinks } from '../Common/SocialLinks';
import { AboutContainer, ABOUT_TYPES } from '../../Common';

function LoginButton(props) {
    return (
        <div className="login-button-wrapper">
            <button>
                <div className="login-button-inner-wrapper">
                    <img src={loginIcon} alt="Login" />
                    <span>Login</span>
                </div>
            </button>
        </div>
    );
}

class NavigationHeader extends React.Component {

    constructor() {
        super();
        this.state = {
            showSidebar: false
        }
        this.toggleSidebarClose = this.toggleSidebarClose.bind(this);
    }

    toggleSidebarClose() {
        this.setState((state) => ({ showSidebar: !state.showSidebar }));
    }

    render() {

        const navbar = this.props.navbar;
        const items = navbar.main_sections || [];

        return (
            <div className="navigation-header-fixed-container">
                <nav className="navigation-header main">

                    <MediaQuery query="(min-width: 600px)">
                        <div className="nav-content nav-content-left">
                            {
                                items.map((item) => (
                                    <div key={item.title}>
                                        <a className="nav-links" href={`#` + item.link_to}>{item.title}</a>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="nav-content nav-content-right">

                            <div><a className="nav-links" href="#faqs">FAQs</a></div>
                            <div><a className="nav-links" href="#contacto">Contacto</a></div>

                            <Link className="nav-links nav-links-login" to="/login">
                                <LoginButton />
                            </Link>

                            <SocialLinks className="nav-social-links"/>
                        </div>
                    </MediaQuery>
                    <MediaQuery query="(max-width: 600px)">
                        <div className="nav-content-min">
                            <img src={landingSidebarIcon} alt="Menu" onClick={this.toggleSidebarClose} />
                            <Link className="nav-links" to="/login">
                                <LoginButton />
                            </Link>
                        </div>
                        {
                            this.state.showSidebar &&

                            <div className="navigation-header-sidebar">
                                <span>Menu!</span>
                                <SidebarMenu onClose={this.toggleSidebarClose}>

                                    {
                                        items.map((item) => (
                                            <div className="sidebar-item" key={item.title}>
                                                <a className="nav-links" href={`#` + item.link_to}>{item.title}</a>
                                            </div>
                                        ))
                                    }
                                    <div className="sidebar-item">
                                        <a className="nav-links" href="#faqs">FAQs</a>
                                    </div>
                                    <div className="sidebar-item">
                                        <a className="nav-links" href="#contacto">Contacto</a>
                                    </div>
                                    <SocialLinks className="sidebar-item navigation-header-icons-min" />
                                    <AboutContainer aboutType={ABOUT_TYPES.GREY} className='about-container-sidebar'/>
                                </SidebarMenu>
                            </div>
                        }
                    </MediaQuery>
                </nav>
            </div>
        );
    }
}

export default NavigationHeader;