import React from "react";
import information from './img/information.svg';
import informationGrey from './img/information-grey.svg';
import './Common.css';

export const ABOUT_TYPES = {
    DEFAULT: 'default',
    GREY: 'grey'
}

export function AboutContainer({ aboutType, className }) {
    const icon = aboutType === ABOUT_TYPES.GREY ? informationGrey : information;
    const extraStyle = aboutType === ABOUT_TYPES.GREY ? ' about-container-grey' : '';
    return (
        <div className={`about-container${extraStyle} ${className}`}>
            <a href="/home">
                <img src={icon} alt="Sobre Mente & Cuerpo" />
                {` `} Sobre Mente & Cuerpo
            </a>
        </div>
    );
}