import React from 'react';
import './Login.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import authClient from '../Auth';
import ForgotPassword from './ForgotPassword';
import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';

class Login extends React.Component {

    constructor() {
        super();
        this.state = {
            redirect: false
        }
        this.handleLogin = this.handleLogin.bind(this);
        this.handleRegisterClick = this.handleRegisterClick.bind(this);
    }

    handleLogin() {
        this.setState({ redirect: true });
    }

    handleRegisterClick() {
        console.log('Redirect to register...');
        this.props.history.push('/signup/steps/0');
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        if (authClient.isAuthenticated()) {
            const user = authClient.getUserData();
            if (user.pendingProfileCompletion()) {
                return <Redirect to={{ pathname: "/complete-profile" }} />;
            } else if (user.hasCompleteProfile()) {
                return <Redirect to={{ pathname: "/complete-profile/treatment" }} />;
            } else if (user.hasSelectedTreatment()) {
                return <Redirect to={{ pathname: "/app" }} />;
            } else {
                return <Redirect to={from} />;
            }
        }

        return (
            <div className="app-form">
                <div className="login-form-container">
                    <div className="form-background">
                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/login" render={() => <LoginForm onLogin={this.handleLogin} onRegisterClick={this.handleRegisterClick}/>} />
                                <Route exact path="/login/forgot-password" component={ForgotPassword} />
                                <Route exact path="/login/reset/:uid/:token" component={ResetPassword} />
                            </Switch>
                        </BrowserRouter>
                    </div>
                </div>
            </div>
        );
    }

}

export default Login;