import React from "react";
import { withRouter } from "react-router-dom";
import { getAppointments } from "../../api";
import AppointmentConfirmation from "../AppointmentConfirmation";
import StatusScreen from "../../StatusScreen";

class AppointmentPaymentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.handleTimeout = this.handleTimeout.bind(this);
        this.status = this.props.match.params.status || 'ok';
        this.state = {
            appointment: null
        }
    }

    fetchAppointments() {
        getAppointments().then(response => {
            const appointment = response.confirmed_appointment;
            if (appointment) {
                this.setState( { appointment: appointment });
            }
        });
    }

    componentWillMount() {
        this.status === 'ok' && this.fetchAppointments();
    }

    handleTimeout() {
        this.props.history.push('/app/turnos');
    }

    render() {
        if (['error'].includes(this.status)) {
            const title = 'ERROR';
            const content = <p className="status-screen-emphasis">
                Su pago no se ha podido procesar. Intente nuevamente en unos minutos.
            </p>;
            const statusStyle = 'error';
            return <StatusScreen title={title} status={statusStyle} content={content} onRedirect={this.handleTimeout} />;
        }
        const appointment = this.state.appointment;
        if (!appointment || this.status !== 'ok') {
            return null;
        }
        return <AppointmentConfirmation appointment={appointment} appointmentTypeId={appointment.modality} onRedirect={this.handleTimeout} />
    }
}

export default withRouter(AppointmentPaymentStatus);