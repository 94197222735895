import React from 'react';

function TeamSection(props) {
    const title = props.team.title;
    const groups = props.team.groups;
    return (
        <div id="equipo" className="main team-section light-section">
            <h1>{title}</h1>
            {
                groups.map((group) => (
                    <div className="team-group" key={group.name}>

                        { group.name && <div className="team-group-title">{group.name}</div> }
                        {
                            <div className="team-members">
                            {
                                group.members.map((member) => (
                                    <div className="team-member" key={member.name}>
                                        <img src={`${process.env.PUBLIC_URL}${member.image_url}`} alt={member.name} />
                                        <div className="member-name">{member.name}</div>
                                        <div className="member-description">{member.description}</div>
                                    </div>
                                ))
                            }
                            </div>
                        }
                    </div>
                ))
            }

        </div>

    );

}

export default TeamSection;