import React from "react";
import "./ContactUs.css";
import nameIcon from "./img/contact-us-person.svg";
import emailIcon from "./img/contact-us-email.svg";
import messageIcon from "./img/contact-us-message.svg";
import { SocialLinks, SOCIAL_TYPES } from "../Common/SocialLinks";
import LoadingPopup from '../../Common/LoadingPopup';
import { sendContactForm } from "../../api";
import Popup from "../../Popup";
import { CloseableWarning } from "../../Appointments/Warning";

class ContactUs extends React.Component {

    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            message: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseWarning = this.handleCloseWarning.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        const form = { name: this.state.name, email: this.state.email, message: this.state.message };
        this.setState({loading: true, responseMessage: '' }, () => {
            sendContactForm(form)
            .then((response) => {
                this.setState( { loading: false, name: '', email: '', message: '', showResponse: true, responseMessage: response.message });
            })
            .catch((error) => {
                this.setState({ loading: false, showResponse: true, responseMessage: 'Hubo un error procesando su solicitud, por favor, intente nuevamente.' })
            });
        });
    }

    handleCloseWarning() {
        this.setState({ showResponse: false });
    }

    render() {
        return (

            <div className="contact-us-section">
                <form onSubmit={this.handleSubmit}>
                    <div className="contact-us-title" id="contacto">Contacto</div>
                    <div className="contact-us-subtitle">Dejanos tu mensaje y nos contactaremos a la brevedad</div>
                    <div className="contact-us-form">
                        <div className="contact-us-form-row">
                            <img src={nameIcon} alt="Nombre" />
                            <input autoCapitalize="none" autoComplete="off" placeholder="Nombre" type="text" name="name" value={this.state.name} onChange={this.handleChange} required />
                        </div>
                        <div className="contact-us-form-row">
                            <img src={emailIcon} alt="Email" />
                            <input autoCapitalize="none" autoComplete="off" placeholder="Email" type="email" name="email" value={this.state.email} onChange={this.handleChange} required />
                        </div>
                        <div className="contact-us-form-row">
                            <img src={messageIcon} alt="Consulta" />
                            <textarea autoCapitalize="none" autoComplete="off" rows="1" cols="30" placeholder="Consulta" type="text" name="message" value={this.state.message} onChange={this.handleChange} required />
                        </div>
                    </div>
                    <button type="submit" className="contact-us-button">
                        Enviar consulta
                    </button>
                </form>

                <SocialLinks className="contact-us-icons" socialType={SOCIAL_TYPES.WHITE} />
                {this.state.loading &&
                    <LoadingPopup>
                        Hola!
                    </LoadingPopup>
                }
                {
                    this.state.showResponse && 
                    <Popup>
                        <CloseableWarning onCancel={this.handleCloseWarning}>
                            <div className="contact-us-success">
                                { this.state.responseMessage }
                            </div>
                        </CloseableWarning>
                    </Popup>
                }
            </div>

        );
    }
}

export default ContactUs;