import React from "react";
import closeIcon from "./img/sidebar-menu-close.svg";
import "./SidebarMenu.css";

class SidebarMenu extends React.Component {

    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onClose && this.props.onClose();
    }

    render() {
        return (
            <div>
                <div className="sidebar-shadow" onClick={this.handleClose}>

                </div>
                <div className="sidebar-menu">
                    <div className="sidebar-close">
                        <img src={closeIcon} alt="Cerrar" onClick={this.handleClose} />
                    </div>
                    <div className="sidebar-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default SidebarMenu;