import React from "react";
import DashboardNavigation from "../Dashboard/DashboardNavigation";
import "./Profile.css";
import { updateProfile } from "../api";
import authClient from "../Auth";
import { Redirect } from "react-router-dom";
import ProfileForm from "../Forms/ProfileForm";

export function toISODateString(year, month, day) {
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

class Profile extends React.Component {

    constructor(props) {
        super(props);
        const user = authClient.getUserData();
        const profile = user.profile;
        const date = new Date(Date.parse(profile.date_of_birth));
        const day = date.getDate() + 1;
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        this.state = {
            loading: false,
            phoneNumber: profile.phone_number,
            address: profile.address,
            country: profile.country,
            state: profile.state,
            city: profile.city,
            postalCode: profile.postal_code,
            currentWeight: profile.current_weight,
            targetWeight: profile.target_weight,
            height: profile.height,
            preexistentDiseases: profile.preexistent_diseases,
            previousTreatments: profile.previous_treatments,
            birthdayDay: day,
            birthdayMonth: month,
            birthdayYear: year,
            updated: false,
            user: user,
            labAnalysis: profile.lab_analysis,
            medicalAptitude: profile.medical_aptitude
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdatedProfile = this.handleUpdatedProfile.bind(this);
    }

    handleSubmit(profileData) {
        var form = profileData;
        form.dateOfBirth = toISODateString(profileData.birthdayYear, profileData.birthdayMonth, profileData.birthdayDay);
        (form.labAnalysis && form.labAnalysis.startsWith('/')) && delete form.labAnalysis;
        (form.medicalAptitude && form.medicalAptitude.startsWith('/')) && delete form.medicalAptitude;
        this.setState({ loading: true }, () => {
            updateProfile(form)
                .then((profile) => {
                    authClient.updateUser('profile', profile);
                    this.setState({ loading: false }, () => this.handleUpdatedProfile());
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ loading: false, genericErrors: error.genericErrors || [error.message], errorStatus: error.errors });
                });
        });
    }

    handleUpdatedProfile() {
        this.setState({ updated: true });
    }

    render() {
        if (this.state.updated) {
            return <Redirect to='/app' />;
        }
        return (
            <div className="edit-profile-main-container">
                <div className="appointments-app">
                    <DashboardNavigation dashboard={this.props.dashboard} hideProfileIcon={true}  />
                    <ProfileForm initialState={this.state} onSubmit={this.handleSubmit} loading={this.state.loading} userProfileURL={this.props.userProfileURL}
                            saveButtonLabel='Guardar' saveButtonClassName='button-red-strong-gradient' genericErrors={this.state.genericErrors} errorStatus={this.state.errorStatus} profilePictureTheme='red'>
                            <div className="edit-profile-header-name">{this.state.user.first_name} {this.state.user.last_name}</div>
                    </ProfileForm>
                </div>
            </div>
        );
    }

}

export default Profile;