import React from 'react';
import './FileUpload.css';
import icon from './uploadIcon.svg';

class FileUpload extends React.Component {

    constructor(props) {
        super();
        this.state = {
            fileName: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.readFile = this.readFile.bind(this);
        this.maxSizeInMB = props.maxSizeInMB || 3;
    }

    handleInputChange(e) {
        if (!e.target.value) { return; }
        const uploadedFile = e.target.files[0];
        if (uploadedFile.size / 1000000 > this.maxSizeInMB) {
            alert(`El archivo elegido supera el tamaño máximo permitido de ${this.maxSizeInMB}MB. Por favor, seleccione otro archivo.`);
            return;
        }
        this.readFile(uploadedFile);
    }

    readFile(uploadedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ fileName: uploadedFile.name });
            this.props.onFileUploadReadEnd && this.props.onFileUploadReadEnd(reader.result);
            console.log(uploadedFile);
        };
        reader.readAsDataURL(uploadedFile);
    }

    render() {
        return (
            <div className="input-file-container">
                <input type="file" accept="image/png,image/jpeg,application/pdf" onChange={this.handleInputChange} name={this.props.id} id={this.props.id} />
                <label htmlFor={this.props.id}>
                    <img src={icon} alt="Subir archivo"/>
                </label>
                <span className="input-file-name profile-input-description">{this.state.fileName}</span>
            </div>
        );
    }

}

export default FileUpload;