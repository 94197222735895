import React from "react";
import './Dashboard.css';
import DashboardMain from "./DashboardMain";
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom';
import Appointments from "../Appointments";
import Notifications from "../Notifications";
import AppointmentPayment from "../Appointments/Payments";
import AppointmentPaymentStatus from "../Appointments/Payments/AppointmentPaymentStatus";
import authClient from "../Auth";
import Profile from "../Profile";
import MyPayments from "../MyPayments";
import Diet from "../Diet";
import { getUserProfile } from "../api";

class Dashboard extends React.Component {

    constructor() {
        super();
        this.state = {
            user: authClient.getUserData()
        }
    }

    componentWillMount() {
        getUserProfile().then((profile) => { 
            authClient.updateProfile(profile);
            this.setState({ user: authClient.getUserData() });
        });
    }

    render() {
        const user = this.state.user;
        const userProfileURL = user && user.profile.picture && `${process.env.REACT_APP_API_HOST}${user.profile.picture}`; // TODO DRY!!
        if (!user.hasSelectedTreatment()) {
            return <Redirect to="/home" />
        }
        const dashboard = user.dashboard;
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={`${this.props.match.path}`} render={() => (<DashboardMain dashboard={dashboard} userProfileURL={userProfileURL} appointmentsConfig={user.profile.appointments_config} />)} />
                    <Route exact path={`${this.props.match.path}/turnos/pagos/:status`} component={AppointmentPaymentStatus} />
                    <Route exact path={`${this.props.match.path}/turnos/pagos`} render={() => (<AppointmentPayment />)} />
                    <Route exact path={`${this.props.match.path}/turnos`} render={() => (<Appointments dashboard={dashboard} userProfileURL={userProfileURL} profile={user.profile} appointmentsConfig={user.profile.appointments_config} />)} />
                    <Route exact path={`${this.props.match.path}/perfil`} render={() => (<Profile dashboard={dashboard} userProfileURL={userProfileURL} />)} />
                    <Route exact path={`${this.props.match.path}/notificaciones`} render={() => (<Notifications dashboard={dashboard} userProfileURL={userProfileURL} user={user} />)} />
                    <Route exact path={`${this.props.match.path}/mis-pagos`} render={() => (<MyPayments dashboard={dashboard} userProfileURL={userProfileURL} user={user} />)} />
                    <Route exact path={`${this.props.match.path}/dieta`} render={() => (<Diet dashboard={dashboard} userProfileURL={userProfileURL} treatment={user.profile.treatment} />)} />
                </Switch>
            </BrowserRouter>

        );
    }
}

export default Dashboard;