import React from 'react';
import { BackButtonHeader } from '../Navigation';
import './Signup.css';
import { signup } from '../api';
import StatusScreen from '../StatusScreen';
import Popup from '../Popup';
import UnderageDisclaimer from './UnderageDisclaimer';
import { Redirect } from "react-router-dom";
import ProfileForm from '../Forms/ProfileForm';
import LoadingPopup from '../Common/LoadingPopup';

class Signup extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.makeInitialState();
        this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUnderageDisclaimerTimeout = this.handleUnderageDisclaimerTimeout.bind(this);
        this.initialState = this.makeInitialState();
    }

    makeInitialState() {
        const now = new Date();
        const year = (now.getFullYear() - 18).toString();
        const month = (now.getMonth() + 1).toString();
        const day = now.getDate().toString();
        return {
            name: '',
            lastName: '',
            identityDocument: '',
            email: '',
            password: '',
            confirmedPassword: '',
            birthdayDay: day,
            birthdayMonth: month,
            birthdayYear: year,
            underageDisclaimer: '',
            loading: false,
            completed: false,
            showUnderageDisclaimer: false,
            showUnderageUpload: false,
            goToLogin: false
        };
    }

    isUnderage(date) {
        const now = new Date();
        const currentYear = now.getFullYear();
        const year = new Date(date).getFullYear();
        const yearDifference = currentYear - year;
        if (yearDifference < 18) {
            return true;
        } else {
            return false;
        }
    }

    padWithZero(value) {
        return value.length >= 2 ? value : `0${value}`;
    }

    getBirthdayString(year, month, day) {
        return `${this.padWithZero(year)}-${this.padWithZero(month)}-${this.padWithZero(day)}`;
    }

    handleSubmit(user) {
        this.setState({ loading: true });
        const birthday = this.getBirthdayString(user.birthdayYear, user.birthdayMonth, user.birthdayDay);
        const userData = {
            'first_name': user.name,
            'last_name': user.lastName,
            'identity_document': user.identityDocument,
            'email': user.email,
            'password': user.password,
            'confirmed_password': user.confirmedPassword,
            'date_of_birth': birthday,
            'picture': user.picture
        };
        signup(userData)
            .then(() => {
                this.setState({ completed: true, loading: false, errorStatus: null });
            })
            .catch(error => {
                const state = { loading: false, genericErrors: error.genericErrors || [error.message], errorStatus: error.errors };
                this.setState(state);
            });
    }

    handleUnderageDisclaimerTimeout() {
        this.setState({ goToLogin: true });
    }

    handleBirthdayChange(birthday) {
        const isUnderage = this.isUnderage(this.getBirthdayString(birthday.year, birthday.month, birthday.day));
        this.setState({ showUnderageDisclaimer: isUnderage });
    }

    render() {
        if (this.state.goToLogin) {
            return <Redirect to="/login"/>;
        }
        if (this.state.completed) {
            const content = <p className="status-screen-emphasis">
            <strong>Inscripción correcta.</strong><br /><br />
                Debe confirmar su e-mail para continuar. 
                Revise su correo no deseado en caso de no recibir un e-mail en los próximos minutos.
            </p>;
            return <StatusScreen status="okBlue" title="¡Felicitaciones!" content={content} />;
        }
        return (
            <div className="app-form signup-container">
                <div className="signup-gradient-background div-blue-gradient">
                    <div className="form-navigation-container">
                        <BackButtonHeader title='Nueva cuenta' goBack={this.props.history.goBack} />
                        <ProfileForm onSubmit={this.handleSubmit} initialState={this.initialState} onBirthdayChange={this.handleBirthdayChange} 
                            saveButtonLabel='Registrarse' saveButtonClassName='button-blue-gradient' genericErrors={this.state.genericErrors} errorStatus={this.state.errorStatus}>
                        </ProfileForm>
                    </div>
                </div>
                {
                    this.state.showUnderageDisclaimer &&
                    <Popup>
                        <UnderageDisclaimer onTimeout={this.handleUnderageDisclaimerTimeout} timeoutInMillis={8000} />
                    </Popup>
                }
                {this.state.loading && <LoadingPopup />}
            </div>
        );
    }
}

export default Signup;