import React from "react";
import "./Warning.css";
import warningBlue from "./img/warning-blue.svg";
import warningRed from "./img/warning-red.svg";
import iconClose from "./img/icon-close.svg";
import GradientButton from "../../GradientButton";
import { createMarkup } from "../../Helpers";

export class WarningFirstAppointment extends React.Component {

    render() {
        return (
            <div className="appointments-warning appointments-warning-fixed-sized">
                <img src={warningBlue} alt="Agendá una consulta" />
                <div className="appointments-warning-text" dangerouslySetInnerHTML={createMarkup(this.props.message)} />
                <GradientButton className="button-blue-gradient" text="Ir a Turnos" onClick={() => this.props.onConfirm()} />
            </div>
        );
    }

}

export class WarningConfirmAppointment extends React.Component {

    render() {
        return (
            <div className={`appointments-warning ${this.props.className}`}>
                <img src={iconClose} className="appointments-warning-close" alt="Cerrar" onClick={() => this.props.onCancel()}/>
                <img src={warningRed} alt="Confirme su turno" />
                <div className="appointments-warning-text" dangerouslySetInnerHTML={createMarkup(this.props.message)} />
                <GradientButton className="button-red-gradient" text="Aceptar" onClick={() => this.props.onConfirm()} />
            </div>
        );
    }

}

export class CloseableWarning extends React.Component {
    render() {
        return (
            <div className={`appointments-warning ${this.props.className}`}>
                <img src={iconClose} className="appointments-warning-close" alt="Cerrar" onClick={() => this.props.onCancel()}/>
                { this.props.children }
             </div>
        );
    }
}