import React from "react";
import DashboardNavigation from "../Dashboard/DashboardNavigation";
import "./Diet.css";
import { createMarkup } from '../Helpers';

// diet depends on the selected treatment.
class Diet extends React.Component {
    render() {
        const treatment = this.props.treatment || {};
        const userProfileURL = this.props.userProfileURL;
        const dashboard = this.props.dashboard;
        return (
            <div className="diet-main-container">
                <div className="appointments-app diets-app">
                    <div className="diet-dashboard-container">
                        <DashboardNavigation userProfileURL={userProfileURL} dashboard={dashboard} title={treatment.name} />
                    </div>
                    <div className="diet-container" dangerouslySetInnerHTML={createMarkup(treatment.diet)}>
                    </div>
                </div>
            </div>
        );
    }
}

export default Diet;