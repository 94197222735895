import React from "react";
import "./Appointments.css";

import DashboardNavigation from "../Dashboard/DashboardNavigation";
import { getAppointments, confirmAppointment, pendingAppointmentManager } from '../api';

import { withRouter } from "react-router-dom";
import CalendarSelection from "./CalendarSelection";
import AppointmentSelection from "./AppointmentSelection";
import { toISODateString } from "../Profile";
import UserAppointment from "./CalendarSelection/UserAppointment";
import Popup from "../Popup";
import { WarningConfirmAppointment } from "./Warning";
import LoadingPopup from "../Common/LoadingPopup";
import authClient from "../Auth";
import MonthSelector from "./MonthSelector";

class AppointmentsManager extends React.Component {

    constructor(props) {
        super(props);
        this.navigateToPaymentScreen = this.navigateToPaymentScreen.bind(this);
        this.handlePreviousMonth = this.handlePreviousMonth.bind(this);
        this.handleNextMonth = this.handleNextMonth.bind(this);
        this.handleConfirmAppointment = this.handleConfirmAppointment.bind(this);
        this.handleConfirmChange = this.handleConfirmChange.bind(this);
        this.handleDaySelection = this.handleDaySelection.bind(this);
        this.handleAppointmentSelection = this.handleAppointmentSelection.bind(this);
        this.fetchAppointments = this.fetchAppointments.bind(this);
        const today = new Date();
        this.state = {
            year: today.getFullYear(),
            month: today.getMonth(),
            selectedDay: null,
            selectedDate: null,
            confirmedAppointment: null,
            loading: true,
            userAppointment: null,
            shouldChargeAppointment: false
        }
        this.today = today;
    }

    handlePreviousMonth() {
        var currentYear = this.today.getFullYear();
        var currentMonth = new Date().getMonth();
        if (this.state.year === currentYear && this.state.month === currentMonth) {
            return;
        }
        var year = this.state.year;
        var month = this.state.month - 1;
        if (month === -1) {
            month = 11;
            year = year - 1;
        }
        this.setState(() => ({ month: month, year: year, selectedDay: null, selectedAvailability: null }));
    }

    handleNextMonth() {
        var year = this.state.year;
        var month = this.state.month + 1;
        if (month === 12) {
            month = 0;
            year = year + 1;
        }
        this.setState(() => ({ month: month, year: year, selectedDay: null, selectedAvailability: null }));
    }

    parseISODateString(isoDate) {
        const components = isoDate.split('-');
        if (components.length !== 3) {
            return {};
        }
        return {
            year: parseInt(components[0]),
            month: parseInt(components[1]),
            day: parseInt(components[2])
        }
    }

    fetchAppointments() {
        getAppointments().then(response => {
            if (response.confirmed_appointment) {
                const { date } = response.confirmed_appointment;
                const parsedDate = this.parseISODateString(date);
                this.setState({
                    year: parsedDate.year,
                    month: parsedDate.month - 1,
                    selectedDay: parsedDate.day,
                    userAppointment: response.confirmed_appointment,
                    availability: response.availability,
                    appointments: response.appointments,
                    appointmentProduct: response.appointment_product,
                    confirmedAppointment: response.confirmed_appointment,
                    loading: false,
                    shouldChargeAppointment: response.should_charge_appointment
                });
            } else {
                this.setState({
                    availability: response.availability,
                    appointments: response.appointments,
                    appointmentProduct: response.appointment_product,
                    confirmedAppointment: response.confirmed_appointment,
                    loading: false,
                    shouldChargeAppointment: response.should_charge_appointment
                });
            }
        }).catch((error) => { this.setState({ loading: false }) });
    }
    componentDidMount() {
        this.fetchAppointments();
    }

    handleConfirmChange() {
        console.log('This should reset state to show available appointments and allow selection.');
        this.setState({ 
            appointments: null, 
            userAppointment: null,
            year: this.today.getFullYear(),
            month: this.today.getMonth(),
            selectedDay: null,
            selectedDate: null,
            changingAppointment: true
         });
    }

    navigateToPaymentScreen() {
        const id = this.state.selectedAppointmentId;
        const product = this.state.appointmentProduct;
        pendingAppointmentManager.save(id, product);
        this.props.history.push('/app/turnos/pagos');
    }

    handleConfirmAppointment() {
        console.log('Perform request to know if we should redirect to MP. Current state:', this.state);
        if ((this.state.confirmedAppointment && this.state.confirmedAppointment.charges && this.state.changingAppointment) || 
                    (!this.state.confirmedAppointment && this.state.shouldChargeAppointment)) {
            // TODO this is the same scenario in which an appointment should be charged, we should check profile to know if user should be charged or not.
            console.log('💣 Should go to MP to pay...!');
            this.navigateToPaymentScreen();
        } else {
            this.setState({ performingRequest: true }, () => {
                confirmAppointment(this.state.selectedAppointmentId)
                .then((response) => {
                    authClient.updateUser('profile', response.profile);
                    this.props.history.push("/app/turnos/pagos/ok");
                })
                .catch((error) => {
                    this.setState({ performingRequest: false });
                });
            });
        }
    }

    handleDaySelection(day) {
        if (this.state.userAppointment) { return; }
        const selectedDate = toISODateString(this.state.year, this.state.month + 1, day);
        console.log(`The day ${day} was selected, let's find availability on day: ${selectedDate}: ${this.state.availability[selectedDate].length}`);
        this.setState({ selectedDay: day, selectedDate: selectedDate, selectedAvailability: this.state.availability[selectedDate] });
    }

    handleAppointmentSelection(appointmentId) {
        this.setState({ showCancelationWarning: true, selectedAppointmentId: appointmentId });
    }

    render() {
        const year = this.state.year;
        const month = this.state.month;
        const day = this.state.selectedDay;
        const enableMonthChange = this.state.userAppointment === null;
        const availability = (this.state.userAppointment === null) && this.state.availability;
        return (
            <div className="appointments">
                <div className="appointments-app">
                    <DashboardNavigation dashboard={this.props.dashboard} userProfileURL={this.props.userProfileURL} />
                    {
                        !this.state.loading &&
                        <div className="appointments-content">
                            <div className="appointments-title">Turnos - {year}</div>
                            <MonthSelector enableChange={enableMonthChange} year={year} month={month} handlePreviousMonth={this.handlePreviousMonth} handleNextMonth={this.handleNextMonth} />
                            <CalendarSelection year={year} month={month} availability={availability} 
                                onDaySelected={this.handleDaySelection} selectedDay={day} />
                            
                            <AppointmentSelection selectedAvailability={this.state.selectedAvailability} 
                                onSelect={this.handleAppointmentSelection} selectedDate={this.state.selectedDate}/>
                            
                            { this.state.userAppointment &&
                                 <UserAppointment appointment={this.state.userAppointment} onConfirmChange={this.handleConfirmChange} /> 
                            }
                            {
                                this.state.showCancelationWarning && <Popup>
                                    <WarningConfirmAppointment onConfirm={this.handleConfirmAppointment} className="appointments-warning-fixed-sized"
                                    onCancel={() => this.setState({ showCancelationWarning: false })} 
                                        message={this.props.appointmentsConfig.change_message} 
                                    />
                                </Popup>
                            }
                            { this.state.performingRequest && <LoadingPopup /> }
                        </div>
                    }
                    { this.state.loading && <LoadingPopup /> }
                </div>
            </div>
        );
    }
}


export default withRouter(AppointmentsManager);