import React from "react";
import GradientButton from "../../GradientButton";
import { WarningConfirmAppointment } from "../Warning";
import Popup from "../../Popup";

class UserAppointment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup: false
        }
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleConfirmPopup = this.handleConfirmPopup.bind(this);
    }

    handleConfirm() {
        console.log('should show confirm:', this.props.appointment);
        if (this.props.appointment.charges) {
            this.setState({ showPopup: true });
        } else {
            this.props.onConfirmChange();
        }
    }

    handleConfirmPopup() {
        this.setState({ showPopup: false }, () => this.props.onConfirmChange());
    }

    render() {
        const appointment = this.props.appointment;

        return (
            <div className="appointments-background-wrapper">
                <div className="appointment-availability-list">
                    <div className="appointment-availability-item">
                        <div className="appointment-user-item">
                            <div>Turno programado</div>
                            <div><strong>{appointment.formatted_day}</strong></div>
                            <div><br/>{appointment.nutritionist}</div>
                        </div>
                    </div>
                    <div className="appointment-user-item-container">
                        <GradientButton text="Cancelar y Reprogramar" className="button-blue-gradient" onClick={this.handleConfirm} />
                    </div>
                    {this.state.showPopup && <Popup>
                        <WarningConfirmAppointment onConfirm={this.handleConfirmPopup}
                            message='Programar otro turno con la nutricionista tiene un costo adicional que no está incluido en el arancel mensual.'
                            onCancel={() => this.setState({ showPopup: false })} />
                    </Popup>}
                </div>
            </div>
        );
    }
}

export default UserAppointment;