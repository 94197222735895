import { login } from "../api";

const USER_DATA = "userData";

class Auth {

    constructor() {
        this.signOut = this.signOut.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.authenticate = this.authenticate.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
    }

    signOut() {
        sessionStorage.clear();
    }

    isAuthenticated() { 
        return sessionStorage.getItem(USER_DATA) != null;
    }

    authenticate(username, password) {
        return login(username, password)
        .then((response) => {
            console.log('Successful login!', response);
            sessionStorage.setItem(USER_DATA, JSON.stringify(response));
        })
    }

    updateUser(key, value) {
        const user = JSON.parse(sessionStorage.getItem(USER_DATA));
        user[key] = value;
        sessionStorage.setItem(USER_DATA, JSON.stringify(user));
    }

    updateProfile(profile) {
        this.updateUser('profile', profile);
    }
    
    getUserData() {
        // TODO What should this function return when userData is null?
        const user = JSON.parse(sessionStorage.getItem(USER_DATA));
        user && (user.pendingProfileCompletion = () => user.profile.status === 'CONFIRMED_EMAIL');
        user && (user.hasCompleteProfile = () => user.profile.status === 'COMPLETED_PROFILE');
        user && (user.hasSelectedTreatment = () => user.profile.status === 'SELECTED_TREATMENT');
        return user;
    }
}

const authClient = new Auth();

export default authClient;