import React from "react";
import { withRouter } from "react-router-dom";
import AppointmentsManager from "./AppointmentsManager";

class Appointments extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangeAppointment = this.handleChangeAppointment.bind(this);
        this.state = {
            changeAppointment: null
        }
    }

    handleChangeAppointment(appointment) {
        this.props.history.push(`${this.props.match.url}/pagos`);
    }

    render() {
        return (<AppointmentsManager dashboard={this.props.dashboard} userProfileURL={this.props.userProfileURL}
             onChangeAppointment={this.handleChangeAppointment} appointmentsConfig={this.props.appointmentsConfig} />);
    }
}


export default withRouter(Appointments);