import React from "react";
import whiteFacebookIcon from "./img/white-fb.svg";
import whiteInstagramIcon from "./img/white-ig.svg";
import grayFacebookIcon from "./img/gray-fb.svg";
import grayInstagramIcon from "./img/gray-ig.svg";

export const SOCIAL_TYPES = {
    GRAY: 'gray',
    WHITE: 'white'
}

export class SocialLinks extends React.Component {

    render() {

        const socialType = this.props.socialType || SOCIAL_TYPES.GRAY;
        const fb = socialType === SOCIAL_TYPES.GRAY ? grayFacebookIcon : whiteFacebookIcon;
        const ig = socialType === SOCIAL_TYPES.GRAY ? grayInstagramIcon : whiteInstagramIcon;
        return (
            <div className={this.props.className}>
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/MenteyCuerpo.BsAs">
                        <img src={fb} alt="¡Seguinos en Facebook!" />
                    </a>
                </div>
                <div
                ><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/menteycuerpo.bsas/">
                        <img src={ig} alt="¡Seguinos en Instagram!" />
                    </a>
                </div>
            </div>
        );
    }
}
