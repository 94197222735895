import React from "react";
import Popup from "../../Popup";
import { Loading } from "../../Forms";
import spinner from '../img/spinner-loading.gif';

export default function LoadingPopup({showSpinner}) {
    return (
        <Popup>
            {
                showSpinner && <div className="spinner-loading ">
                    <img src={spinner} alt='Cargando...' />
                </div>
            }
            <Loading />
        </Popup>
    );
}