import React from 'react';
import nextRed from './nextButtonRed.svg';
import nextBlue from './nextButtonBlue.svg';
import nextGreen from './nextButtonGreen.svg';
import back from './backButton.svg';
import './Navigation.css';

const stylesToButtons = {
    'red': nextRed,
    'blue': nextBlue,
    'green': nextGreen
}

export function Next(props) {
    const buttonSrc = stylesToButtons[props.styleName] || nextRed;
    return (
        <img src={buttonSrc} alt="Siguiente" className="navigation-button-next" />
    );
}

export function Back() {
    return (
        <img src={back} alt="Anterior" className="navigation-button-back" />
    );
}

export function BackButtonHeader(props) {
    return (
        <div className="navigation-container">
            <button className="empty-button" onClick={props.goBack}>
                <Back />
            </button>
            <div className="navigation-container-title">{props.title}</div>
        </div>
    );
}
