import React from 'react';
import './StatusScreen.css';
import statusOKBlue from './img/statusOkBlue.svg';
import statusOKRed from './img/statusOkRed.svg';
import statusOKGreen from './img/statusOkGreen.svg';
import statusError from './img/statusError.svg';

const statusImages = {
    error: statusError,
    okRed: statusOKRed,
    okBlue: statusOKBlue,
    okGreen: statusOKGreen
}

const statusStyles = {
    error: 'status-screen-error',
    okRed: 'treatment-selection-gradient-div-red',
    okBlue: 'treatment-selection-gradient-div-blue',
    okGreen: 'treatment-selection-gradient-div-green'
}

const DEFAULT_TIMEOUT_MSECS = 4000;

class StatusScreen extends React.Component {

    constructor(props) {
        super(props);
        this.handleTimeout = this.handleTimeout.bind(this);
        this.timeBeforeRedirect = this.props.timeBeforeRedirect || DEFAULT_TIMEOUT_MSECS;
    }

    handleTimeout() {
        if (this.timeBeforeRedirect > 0 && this.props.onRedirect) {
            setTimeout(() => {
                this.props.onRedirect();
            }, this.timeBeforeRedirect);
        }
    }

    componentDidMount() {
        this.handleTimeout();
    }

    render() {
        const title = this.props.title;
        const img = statusImages[this.props.status];
        const style = statusStyles[this.props.status];

        return (
            <div className={`status-screen ${style}`}>
                
                <div className="status-screen-container">
                    
                    <h1 className="status-screen-title">{title}</h1>
                    
                    <img src={img} alt={title}/>

                    {this.props.content}
                </div>

            </div>
        );
    }

}

export default StatusScreen;