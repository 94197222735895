import React from "react";

class AppointmentDay extends React.Component {

    static DISABLED = 'disabled';
    static NOT_AVAILABLE = 'not-available';
    static AVAILABLE = 'available';
    static SELECTED = 'selected';
    static WEEK_DAY = 'week-day';

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if ((this.props.status === AppointmentDay.AVAILABLE || this.props.status === AppointmentDay.SELECTED) && this.props.onDaySelected) {
            this.props.onDaySelected(this.props.day);
        }
    }

    render() {
        const day = this.props.day;
        const status = this.props.status;
        return (
            <div className={`appointment-day-container appointment-day-container-${status}`} onClick={this.handleClick}>
                <div className={`appointment-day appointment-day-${status}`}>
                    <div className="appointment-day-text">
                        {day}
                    </div>
                </div>
                {
                    (status === AppointmentDay.SELECTED || status === AppointmentDay.AVAILABLE) &&
                    <div className="appointment-notification" />
                }
            </div>
        )
    }
}

export default AppointmentDay;