import React from 'react';
import './GradientButton.css';

function GradientButton({ className, ...props }) {
    return (
        <button type={props.type} className={`gradient-button gradient-div ${className}`} onClick={props.onClick}>
            {props.text}
        </button>
    );
}

export default GradientButton;

