import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Landing from './Landing';
import Login from './Login';
import Signup from './Signup';
import Walkthrough from './Signup/Walkthrough';
import CompleteProfile from './Registration';
import TreatmentSelection from './TreatmentSelection';
import PaymentStatus from './Payment/PaymentStatus';
import './App.css';
import PrivateRoute from './Routes';
import MercadoPago from './Payment/MercadoPago';
import { ProfilePictureDemo } from './ProfilePicture';
import Dashboard from './Dashboard';
import WhatsAppSticky from './Common/WhatsAppSticky';

class App extends Component {

  constructor() {
    super();
    this.handleDisplayStatusTimeout = this.handleDisplayStatusTimeout.bind(this);
  }

  componentWillMount() {
    console.info('App will mount');
    localStorage.clear(); // TODO Remove after demo, this should clear user's persistent cache.
  }

  handleDisplayStatusTimeout() {
    window.location = '/login';
  }


  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            {process.env.NODE_ENV !== 'production' && <Route path="/signup2" component={ProfilePictureDemo} />}
            <Route exact path="/signup/steps/:id" component={Walkthrough} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/pagos" component={MercadoPago} />
            <PrivateRoute exact path="/payment/:status" component={() => <PaymentStatus handleDisplayStatusTimeout={this.handleDisplayStatusTimeout} />} />
            <PrivateRoute exact path="/complete-profile" component={CompleteProfile} />
            <PrivateRoute path="/complete-profile/treatment/:id/step/:stepId" component={TreatmentSelection} />
            <PrivateRoute path="/complete-profile/treatment/:id?" component={TreatmentSelection} />
            <PrivateRoute path="/app" component={Dashboard} />
            <Route component={Landing} />
          </Switch>
        </BrowserRouter>
        <WhatsAppSticky/>
      </div>
    );
  }
}

export default App;
