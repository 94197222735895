import React from "react";
import { createMarkup } from '../../Helpers';
import './AboutUsSection.css';

function LightSection({ id, className, section }) {
    return (
        <div id={id} className={className}>
            <h1>{section.title}</h1>
            <h2>{section.subtitle}</h2>
            <p dangerouslySetInnerHTML={createMarkup(section.content)}>
            </p>
        </div>
    );
}

function AboutUsSection(props) {
    return (
        <div className="about-us-section main">
            <div className="company-history-layer">
                <div className="company-history-container">
                    <LightSection id="nosotros" className="company-box our-history light-section" section={props.company} />
                    <LightSection id="historia" className="history-box our-history light-section" section={props.history} />
                </div>
            </div>
            <div className="mission-values-layer">
                <div id="mision" className="about-section">
                    {
                        props.about.items.map((item) => (
                            <div className="about-item" key={item.title}>
                                <div className="about-item-title">{item.title}</div>
                                <div className="about-item-text">{item.text}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );

}

export default AboutUsSection;