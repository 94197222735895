import React from "react";
import "./WhatsAppSticky.css"

export default class WhatsAppSticky extends React.Component {

    render() {
        return (<a href="https://api.whatsapp.com/send?phone=5491133476134" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-whatsapp whatsapp-float-custom"></i>
        </a>);
    }
}
