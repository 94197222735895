import React from 'react';
import { createMarkup } from '../../Helpers';
import './TreatmentSection.css';

function TreatmentSection(props) {
    const treatment = props.treatment;
    return (
        <div id="seguimiento" className="treatment-section">
            <div className="main treatment-header">
                <div className="treatment-header-title">{treatment.title}</div>
                <div className="treatment-header-subtitle">{treatment.subtitle}</div>
                <div className="treatment-header-p" dangerouslySetInnerHTML={createMarkup(treatment.content)}></div>

                <div className="treatment-items">
                    {
                        treatment.items.map((item) => (
                            <div className="treatment-item" key={item.title}>
                                <img src={`${process.env.PUBLIC_URL}${item.icon}`} alt={item.title} />
                                <div className="treatment-item-title">{item.title}</div>
                                <div dangerouslySetInnerHTML={createMarkup(item.content)}>

                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    );

}

export default TreatmentSection;