import React from "react";
import AppointmentAvailabilityList from "./Availability";

class AppointmentSelection extends React.Component {

    render() {
        return (

            <div className="appointments-background-wrapper">
            {
                this.props.selectedAvailability &&
                <AppointmentAvailabilityList availability={this.props.selectedAvailability} onSelect={this.props.onSelect} key={this.props.selectedDate} />
            }

        </div>
        );
    }

}

export default AppointmentSelection;