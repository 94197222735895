import logo from './main.logo-white.svg';
import React, { Component } from 'react';
import NavigationHeader from './NavigationHeader';
import AboutUsSection from './AboutUsSection';
import { getLanding } from './data';
import TeamSection from './TeamSection';
import TreatmentSection from './TreatmentSection';
import FAQSection from './FAQSection';
import ContactUs from './ContactUs';
import FooterSection from './FooterSection';
import './Landing.css';
import LandingTerms from './LandingTerms';

class Landing extends Component {
    constructor() {
        super();
        this.state = getLanding();
    }

    render() {
        return (
            <div>
                {
                    this.state.showTerms && <LandingTerms onCancel={() => this.setState({ showTerms: false })} />
                }
                <NavigationHeader navbar={this.state.navbar} />
                <div className="landing-container">
                    <div className="background-container"></div>
                    <div className="main landing-content">

                        <div className="navbar-container">

                            <div className="landing-logo-wrapper">
                                <img src={logo} alt="logo" />
                                <a href="/signup/steps/0" className="landing-call-to-start">
                                    EMPEZAR
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="landing-first-container">
                    <div className="main landing-offset-section">
                        <AboutUsSection history={this.state.history} about={this.state.about} company={this.state.company} />
                        <TeamSection team={this.state.team} />
                    </div>
                </div>

                <TreatmentSection treatment={this.state.treatment} />
                <FAQSection faq={this.state.faq} />
                <ContactUs />
                <FooterSection footer={this.state.footer} onTermsClick={() => this.setState({ showTerms: true })} />
            </div>

        );
    }
}

export default Landing;