import React from "react";
import { getPayments, sendEndTreatmentRequest } from "../api";
import DashboardNavigation from "../Dashboard/DashboardNavigation";
import paymentConfirmedIcon from "./img/payment-confirmed.svg";
import Payment from "../Payment";
import "./MyPayments.css";
import Popup from "../Popup";
import { CloseableWarning } from "../Appointments/Warning";
import LoadingPopup from "../Common/LoadingPopup";
import GradientButton from "../GradientButton";

class HistoricalPayment extends React.Component {

    constructor(props) {
        super(props);
        this.formatter = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' });
    }

    render() {
        const payment = this.props.payment || {};
        return (
            <div className="main-historical-payment-container">
                <div className="historical-payment-vertical-container">
                    <div className="historical-payment-amount">{this.formatter.format(payment.amount)}</div>
                    <div className="historical-payment-description">{payment.description}</div>
                </div>
                <div>
                    <img src={paymentConfirmedIcon} alt='Pago confirmado' />
                </div>
            </div>
        );
    }
}


class MyPayments extends React.Component {

    constructor(props) {
        super(props);
        this.handlePayPressed = this.handlePayPressed.bind(this);
        this.fetchPayments = this.fetchPayments.bind(this);
        this.state = {
            payments: {},
            loading: true,
            error: null
        }
        this.handleEndTreatmentConfirmed = this.handleEndTreatmentConfirmed.bind(this);
    }

    handlePayPressed() {
        const nextPayment = this.state.payments.next_payment;
        const preferenceUrl = nextPayment && nextPayment.preference_url;
        if (!preferenceUrl) return;
        window.location = preferenceUrl;
    }

    handleEndTreatmentConfirmed() {
        this.setState({ showEndTreatmentQuestion: false, loading: true }, () => {
            sendEndTreatmentRequest()
            .then(() => {
                this.setState( { loading: false }, () => this.setState({ showEndTreatmentConfirmation: true }))
            })
            .catch((error) => this.setState({ loading: false, showEndTreatmentError: true }))
        });
    }

    fetchPayments() {
        getPayments()
        .then(response => {
            console.log(response);
            this.setState({ payments: response, loading: false });
        })
        .catch((error) => {
            this.setState({ error: error, loading: false });
        });
    }

    componentDidMount() {
        this.fetchPayments();
    }

    render() {
        const pendingPayment = this.state.payments.next_payment;
        return (
            <div className="my-payments-main-container">
                <div className="appointments-app my-payments-app">
                    <DashboardNavigation userProfileURL={this.props.userProfileURL} dashboard={this.props.dashboard} title='Mis Pagos' />
                    <div className="my-payments-container">
                        {
                            this.state.error && <div>{this.state.error.message}</div>
                        }
                        {
                            pendingPayment &&
                            <div className="pending-payment-container">
                                <Payment description={pendingPayment.title} amount={pendingPayment.amount} onPayPressed={this.handlePayPressed} styleName='white' />
                            </div>
                        }
                        {
                            this.state.payments.history &&
                            <div>
                                <div className="last-payments-title">
                                    Últimos Pagos
                                </div>
                                {
                                    this.state.payments.history.map((payment) => (
                                        <HistoricalPayment key={payment.id} payment={payment} />
                                    ))
                                }                             
                            </div>
                        }
                    </div>
                </div>
                <div className="end-treatment-footer" onClick={() => this.setState({ showEndTreatmentQuestion: true })}>
                    Dar de baja 
                </div>
                { this.state.loading && <LoadingPopup /> }
                { this.state.showEndTreatmentQuestion &&
                    <Popup>
                        <CloseableWarning onCancel={() => this.setState({ showEndTreatmentQuestion: false })}>
                            <div className="end-treatment-confirmation">
                                ¿Confirma la baja del programa?
                            </div>
                            <GradientButton className="button-red-gradient" text="Confirmar" onClick={this.handleEndTreatmentConfirmed} />
                        </CloseableWarning>
                    </Popup>
                }
                { this.state.showEndTreatmentConfirmation && <Popup>
                    
                    <CloseableWarning onCancel={() => this.setState({ showEndTreatmentConfirmation: false })} >
                        <div className="end-treatment-confirmation">
                            El pedido de baja ya fue notificado a administración.
                        </div>
                    </CloseableWarning>
                   
                </Popup>}
                { this.state.showEndTreatmentError && <Popup>
                    
                    <CloseableWarning onCancel={() => this.setState({ showEndTreatmentError: false })} >
                        <div className="end-treatment-confirmation">
                            El pedido de baja no pudo ser confirmado. Intente nuevamente más tarde.
                        </div>
                    </CloseableWarning>
                   
                </Popup> }
            </div>
        )
    }
}

export default MyPayments;