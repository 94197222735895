import React from "react";
import StatusScreen from "../StatusScreen";

export default function AppointmentConfirmation(props) {
    const title = '¡Felicitaciones!';
    const availability = props.appointment;
    const content = <div className='status-screen-content'>
        <div>Turno programado con éxito </div>
        <div><b>{availability.formatted_day}</b></div>
        <div><br/>{availability.nutritionist} </div>
    </div>;
    //TODO make okBlue static
    return <StatusScreen title={title} status='okBlue' content={content} onRedirect={props.onRedirect} />;
}