import React from 'react';
import './Login.css';
import user from '../images/user.svg';
import lock from '../images/lock.svg';
import { Link } from 'react-router-dom';
import GradientButton from '../GradientButton';
import authClient from '../Auth';
import { ErrorMessagesList } from '../Forms';
import LoadingPopup from '../Common/LoadingPopup';
import { AboutContainer } from '../Common';
import logo from './main.logo-white.svg';

class LoginForm extends React.Component {

    state = {
        redirectToReferrer: false,
        username: '',
        password: '',
        loading: false
    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(event) {
        const target = event.target;
        this.setState({ [target.name]: target.value });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        this.setState({ loading: true }, () => {
            authClient.authenticate(this.state.username, this.state.password)
                .then(() => {
                    this.props.onLogin();
                })
                .catch(error => {
                    const state = { loading: false, genericErrors: error.genericErrors || [error.message], errorStatus: error.errors };
                    this.setState(state);
                })
        });
    }

    render() {

        return (
            <form onSubmit={this.handleSubmit}>
                {this.state.loading && <LoadingPopup />}
                <div className="logo-white-container">
                    <img src={logo} alt="logo" className="logo-white" />
                </div>
                <div className="inputs-group-container">
                    <div className="inputs-group inputs-group-center">
                        <div className="icon-input-container">
                            <img src={user} alt="Email" />
                            <div className="input-container">
                                <input type="email" autoCapitalize="none" autoComplete="off" onChange={this.handleOnChange} value={this.state.username} name="username" required />
                                <div className="profile-input-description">Email</div>
                            </div>
                        </div>
                        <div className="icon-input-container">
                            <img src={lock} alt="Contraseña" />
                            <div className="input-container">
                                <input type="password" onChange={this.handleOnChange} value={this.state.password} name="password" required />
                                <div className="profile-input-description">Contraseña</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-footer">

                    <div className="register-container">
                        <span>¿No tenés una cuenta? {` `}
                        </span>
                        <span className="register-link" onClick={this.props.onRegisterClick}><i>Registrate</i></span>

                    </div>
                    <GradientButton type="submit" text="Ingresar" />
                    {this.state.genericErrors &&
                        <div className="messages-container">
                            <ErrorMessagesList errors={this.state.genericErrors} />
                        </div>
                    }

                    <div className="forgot-password-link">
                        <Link to="/login/forgot-password">
                            Olvidé mi contraseña
                        </Link>

                    </div>
                    <AboutContainer className='about-container-login' />
                </div>
            </form>
        );
    }

}

export default LoginForm;