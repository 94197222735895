import React from 'react';
import './Payment.css';
import okBlue from './img/okBlue.svg';
import okRed from './img/okRed.svg';
import okGreen from './img/okGreen.svg';
import okWhite from './img/okWhite.svg';
import mercadoPago from './img/mercadoPago.svg';
import Popup from '../Popup';
import LegalWarning from './LegalWarning';

const styleToButton = {
    'red': okRed,
    'blue': okBlue,
    'green': okGreen,
    'white': okWhite
}

class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.handlePay = this.handlePay.bind(this);
        this.handleWarningAccepted = this.handleWarningAccepted.bind(this);
        this.state = {
            showWarning: this.props.showWarning || false
        }
    }

    handleWarningAccepted() {
        this.setState({ showWarning: false });
    }

    handlePay() {
        this.props.onPayPressed();
    }

    render() {
        const buttonSrc = styleToButton[this.props.styleName] || okRed;
        const description = this.props.description;
        const amount = this.props.amount || 0;
        return (
            <div className="payment-container">
                <div className="payment-container-main">
                    <div className="payment-title">
                        { description }
                    </div>
                    <div className="payment-amount">
                        <span className="extras-amount dollar-sign">$</span>
                        <span className="main-amount">
                        {new Intl.NumberFormat('es-AR', {  }).format(amount)}
                        </span>
                        <span className="extras-amount decimal-part">00</span>
                    </div>
                    <div className="payment-subtitle">
                        <span>Abonar utilizando</span>
                    </div>
                    <img src={mercadoPago} alt='Pagar con Mercago Pago' />
                </div>
                <button onClick={this.handlePay} className="payment-container-button">
                    <img src={buttonSrc} alt='Pagar' />
                </button>
                { this.state.showWarning &&
                    <Popup>
                        <LegalWarning onWarningAccepted={this.handleWarningAccepted} onCancel={this.props.history.goBack}/>
                    </Popup>
                }
            </div>

        );
    }
}

export default Payment;