import React from 'react';
import LoadingPopup from '../Common/LoadingPopup';
import logo from './main.logo-white.svg';
import './Login.css';
import './ResetPassword.css';
import GradientButton from '../GradientButton';
import { changePassword } from '../api';

export default class ResetPassword extends React.Component {

    constructor() {
        super();
        this.state = {
            password: '',
            repeatPassword: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(event) {
        const target = event.target;
        this.setState({ [target.name]: target.value });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        if (this.state.password !== this.state.repeatPassword) {
            this.setState({ message: ['Las contraseñas no coinciden.']});
            return;
        }
        const uid = this.props.match.params.uid;
        const token = this.props.match.params.token;
        const password = this.state.password;
        changePassword(uid, token, password)
        .then((response) => {
            this.setState({ message: response.message, loading: false }, () => {
                this.props.history.push('/login');
            });
        })
        .catch((error) => {
            var message = '';
            if (error.message) {
                message = error.message;
            } else if (error.errors && error.errors['password']) {
                message = error.errors['password'];
            } else if (error.genericErrors && error.genericErrors.length > 0) {
                message = error.genericErrors[0];
            }
            this.setState({ message: message, loading: false });
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.state.loading && <LoadingPopup />}
                <div className="logo-white-container">
                    <img src={logo} alt="logo" className="logo-white" />
                </div>
                <div className="inputs-group-container">
                    <div className="inputs-group inputs-group-center">
                        <div className="icon-input-container">
                            <div className="input-container">
                                <input type="password" onChange={this.handleOnChange} value={this.state.password} name="password" required minLength='8' pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$' />
                                <div className="profile-input-description">Nueva contraseña de Mente & Cuerpo</div>
                            </div>
                        </div>
                        <div className="icon-input-container">
                            <div className="input-container">
                                <input type="password" onChange={this.handleOnChange} value={this.state.repeatPassword} name="repeatPassword"  required minLength='8' pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$' />
                                <div className="profile-input-description">Confirmar contraseña</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-footer">
                    <div className="reset-password-message">
                        La contraseña nueva debe tener al menos 8 caracteres y contener tanto letras como números
                    </div>
                    <GradientButton type="submit" text="Restablecer contraseña" />
                    {this.state.message && <div className="forgot-password-message">{this.state.message}</div>}
                </div>
            </form>
        );
    }
}