import React, { Component } from 'react';
import { createMarkup } from '../Helpers';

class FAQSection extends Component {

    render() {

        return (<div className="light-section grayed-section faq-section">
            <div className="main" id="faqs">
                <h1>{this.props.faq.title}</h1>
                <h2>{this.props.faq.subtitle}</h2>

                {
                    this.props.faq.questions.map((item, index) => (
                        <div key={item.question} >
                            <FAQItem item={item} />
                            {index < this.props.faq.questions.length - 1 && <div className="question-separator"></div>}
                        </div>
                    ))
                }
            </div>


        </div>);
    }

}

class FAQItem extends Component {

    constructor() {
        super();
        this.state = {
            collapsed: true
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState((state) => ({ collapsed: !state.collapsed }))
    }

    render() {
        return (<div className="faqs">

            <div>
                <button className="faq-button" onClick={this.handleClick}>
                    <div className="faq-row">
                        <div className="faq-button-symbol">
                            {this.state.collapsed ? '+' : '-'}
                        </div>
                        <div className="faq-item-question" dangerouslySetInnerHTML={createMarkup(this.props.item.question)}></div>
                    </div>
                </button>
            </div>
            <div className="faq-item-answer" hidden={this.state.collapsed} dangerouslySetInnerHTML={createMarkup(this.props.item.answer)}>

            </div>

        </div>);
    }

}

export default FAQSection;