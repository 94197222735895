import React from 'react';
import './Popup.css';

class Popup extends React.Component {

    render() {
        return (
            <div className="popup">
                {this.props.children}
            </div>
        );
    }

}

export default Popup;