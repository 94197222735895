import React from 'react';
import { Link } from "react-router-dom";
import { getWalkthrough } from '../data';
import './Walkthrough.css';
import { Next, BackButtonHeader } from '../../Navigation';
import { createMarkup } from '../../Helpers';

class Walkthrough extends React.Component {

    constructor({ match, history }) {
        super();
        this.state = {
            currentIndex: Number(match.params.id) || 0,
            history: history
        };
        const walkthrough = getWalkthrough();
        this.urlPrefix = walkthrough.urlPrefix;
        this.lastStepUrl = walkthrough.lastStepUrl;
        this.steps = walkthrough.steps; // FIXME!
        this.makeNextUrl = this.makeNextUrl.bind(this);
    }

    makeNextUrl() {
        const count = this.steps.length;
        if (this.state.currentIndex === count - 1) {
            return this.lastStepUrl;
        }
        const next = this.state.currentIndex + 1;
        return `${this.urlPrefix}/${next}`;
    }

    componentWillReceiveProps(props) {
        this.setState(state => ({
            currentIndex: Number(props.match.params.id)
        }));
    }

    render() {
        const currentStep = this.steps[this.state.currentIndex];
        return (
            <div className="walkthrough-container">
                <div className="gradient-div">
                    <div className="walkthrough-main">
                        <BackButtonHeader title={currentStep.title} goBack={this.state.history.goBack} />
                        <div className="navigable-content">
                            <div className="markup-container"
                                dangerouslySetInnerHTML={createMarkup(currentStep.content)}>
                            </div>
                            <Link to={this.makeNextUrl()}><Next /></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Walkthrough;