import React from 'react';
import { createMarkup } from '../Helpers';

function FooterSection(props) {


    return (
        
        <div className="footer">
            <span dangerouslySetInnerHTML={createMarkup(props.footer.text)}></span>
            <span className="footer-terms" onClick={() => props.onTermsClick && props.onTermsClick()}>
                Términos y condiciones.
            </span>
        </div>
    );
}

export default FooterSection;
