import React from 'react';
import { updateProfile } from '../api';
import { BackButtonHeader } from '../Navigation';
import { Redirect } from "react-router-dom";
import './LocationInput.css';
import './Registration.css';
import authClient from '../Auth';
import ProfileForm from '../Forms/ProfileForm';
import LoadingPopup from '../Common/LoadingPopup';

class CompleteSignupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            phoneNumber: '',
            address: '',
            country: '',
            state: '',
            city: '',
            postalCode: '',
            currentWeight: '',
            targetWeight: '',
            height: '',
            preexistentDiseases: '',
            previousTreatments: '',
            labAnalysis: null,
            medicalAptitude: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(profile) {
        this.setState({ loading: true }, () => {
            updateProfile(profile)
                .then((profile) => {
                    authClient.updateUser('profile', profile);
                    this.setState({ loading: false }, () => this.props.onSignupFormCompleted());
                })
                .catch((error) => {
                    this.setState({ loading: false, genericErrors: error.genericErrors || [error.message], errorStatus: error.errors });
                });
        });
    }

    render() {
        const user = this.props.user;
        const userProfileURL = user && user.profile.picture && `${process.env.REACT_APP_API_HOST}${user.profile.picture}`; // TODO DRY!!
        return (
            <div className="app-form signup-container">
                <div className="signup-gradient-background div-blue-gradient">
                    <div className="form-navigation-container">
                        <BackButtonHeader title='Nueva cuenta' goBack={this.props.history.goBack} />

                        <ProfileForm initialState={this.state} onSubmit={this.handleSubmit} loading={this.state.loading} userProfileURL={userProfileURL}
                            saveButtonLabel='Guardar' saveButtonClassName='button-blue-gradient' genericErrors={this.state.genericErrors} 
                            errorStatus={this.state.errorStatus} showMedicalDisclaimer={true}>
                            <div className="profile-picture-header-name">{this.props.user.first_name} {this.props.user.last_name}</div>
                            <div className="profile-picture-header-message">Seguí completando tu perfil</div>
                        </ProfileForm>
                    </div>
                </div>
                {this.state.loading && <LoadingPopup />}
            </div>

        );
    }

}

class CompleteProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            completed: false,
            user: authClient.getUserData()
        }
        console.log("Complete profile: ", this.state.user, this.state.user.pendingProfileCompletion());
        this.handleSignupFormCompleted = this.handleSignupFormCompleted.bind(this);
    }

    handleSignupFormCompleted() {
        console.log("Should change state...");
        this.setState({ user: authClient.getUserData(), completed: true });
    }

    userStatusCommands = {
        CONFIRMED_EMAIL: () => { return <CompleteSignupForm history={this.props.history} onSignupFormCompleted={this.handleSignupFormCompleted} user={this.state.user} /> },
        COMPLETED_PROFILE: () => { return <Redirect to="/complete-profile/treatment" /> }
    }

    resolveStatus(status) {
        const strategy = this.userStatusCommands[status];
        if (!strategy) {
            return () => <Redirect
                to={{
                    pathname: "/home",
                    state: { from: this.props.location }
                }} />
        }
        return strategy;
    }

    render() {
        const status = this.state.user.profile.status;
        return this.resolveStatus(status)();
    }
}

export default CompleteProfile;