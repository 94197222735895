import React from "react";
import "./DashboardMenu.css";

export default function Badge({ count }) {

    return (
        <span className="badge">
            { count }
        </span>
    );

}