export function getWalkthrough() {
    return {
        lastStepUrl: '/signup',
        urlPrefix: '/signup/steps',
        steps: [
            {
                title: 'Nuestro seguimiento',
                content: '<p>M&C es un grupo terapéutico de descenso veloz de peso.</p> <p>En M&C ofrecemos un programa <strong>online</strong> de atención personalizada, que incluye fines de semana y feriados, con un seguimiento diferente 24 hs.</p> <p>Generamos un espacio para conectarnos con nosotros y concientizar el cuidado de nuestro cuerpo. Lo hacemos a través de grupos de whatsapp. <br/>Con participantes de Buenos Aires, el interior, y el exterior, los miembros del grupo asumen un compromiso de WhatsApp al ingresar. Cada etapa tiene sus pautas y su compromiso.</p>'
            },
            {
                title: 'Entrenamiento',
                content: '<ul> <li>Entrenamos para que la persona que tenga una conducta adictiva con la comida sea capaz de afrontar la abstinencia.</li> <li>Entrenamos para poder detectar las situaciones de riesgo y anticiparnos</li> <li>Entrenamos para tener una medida</li> <li>Entrenamos para poder cortar</li> <li>Entrenamos para saber pedir ayuda</li> <li>Entrenamos para finalmente; tener un peso saludable! </li>  </ul>'
            },
            {
                title: 'El WhatsApp',
                content: '<p>Los grupos de WhatsApp ofrecen un sentido de pertenencia. La participación diaria, todo lo que se comparte genera un vínculo muy fuerte, un compromiso, una meta común; el peso deseado y saludable.</p> <p>Los grupos:</p> <ul><li>10 integrantes</li> <li>2 Coordinadores</li> <li>2 Asistentes de coordinación</li> <li>1 Nutricionista</li> </ul>'
            },
            {
                title: 'Las Etapas',
                content: '<p>El proceso que M&C propone consta de 3 etapas. </p> <ul> <li>Descenso</li> <li>Pre-Mantenimiento</li> <li>Mantenimiento</li> </ul> <p>Cumplir con estas tres etapas nos dan las herramientas para llegar y transitar un peso deseado y saludable. Cuidando un nuevo estado de Mente y Cuerpo.</p>'
            }
        ]
    }
}