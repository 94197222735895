import React from "react";

import chevronLeft from './img/chevron-left.svg';
import chevronRight from './img/chevron-right.svg';

class MonthSelector extends React.Component {

    getNameOfMonth(year, month) {
        const date = new Date(year, month);
        return date.toLocaleString("es-ar", { month: "long" }).toUpperCase();
    }

    render() {
        return (
            <div className="appointments-month">
                <div className="appointments-month-line"></div>
                <div className="appointments-month-selector">
                    <div>
                        { this.props.enableChange && <img src={chevronLeft} alt="Anterior" onClick={this.props.handlePreviousMonth} /> }
                    </div>
                    <div>
                        {this.getNameOfMonth(this.props.year, this.props.month)}
                    </div>
                    <div>
                        { this.props.enableChange && <img src={chevronRight} alt="Siguiente" onClick={this.props.handleNextMonth} /> }
                    </div>
                </div>
                <div className="appointments-month-line"></div>
            </div>
        );
    }

}

export default MonthSelector;