import React from "react";
import Payment from "../../Payment";
import { BackButtonHeader } from "../../Navigation";
import { withRouter } from "react-router-dom";
import { payAppointment, pendingAppointmentManager } from "../../api";
import LoadingPopup from "../../Common/LoadingPopup";

class AppointmentPayment extends React.Component {

    constructor(props) {
        super(props);
        this.handlePayment = this.handlePayment.bind(this);
        this.appointment = pendingAppointmentManager.get();
        this.state = {
            loading: false
        }
    }

    handlePayment() {
        this.setState({ loading: true }, () => {
            payAppointment(this.appointment.id)
            .then((data) => {
                this.setState({ loading: false });
                pendingAppointmentManager.remove();
                const url = data['init_point'];
                if (url) {
                    window.location = url;
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
        })
    }

    render() {
        const appointment = this.appointment;
        if (!appointment) {
            return null;
        }
        return (
            <div className="treatment-selection-container">
                <div className={`treatment-selection-gradient-div treatment-selection-gradient-div-blue`}>
                    <div className="treatment-selection-main">
                        <BackButtonHeader title='Pago' goBack={this.props.history.goBack} />
                        <div className="treatment-selection-content">
                            <Payment styleName='blue' amount={appointment.product.unit_price} description={appointment.product.description} onPayPressed={this.handlePayment} />
                        </div>
                        { this.state.loading && <LoadingPopup /> }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AppointmentPayment);