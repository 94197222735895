import React from "react";
import { withRouter } from "react-router";
import DashboardNavigation from "./DashboardNavigation";
import { Link } from "react-router-dom";
import { WarningFirstAppointment } from "../Appointments/Warning";
import Popup from "../Popup";

class DashboardMain extends React.Component {

    constructor(props) {
        super();
        this.handleConfirmWarning = this.handleConfirmWarning.bind(this);
    }

    handleConfirmWarning() {
        this.props.history.push('/app/turnos');
    }

    render() {
        const appointmentsConfig = this.props.appointmentsConfig || {};
        const showAppointmentRequest = appointmentsConfig.show_appointment_request;
        const showAppointmentRequestMessage = appointmentsConfig.show_appointment_request_message;
        return (
            <div className="dashboard">
                <div className="dashboard-profile-container" style={{ backgroundImage: `url(${this.props.userProfileURL})` }} >
                    <div className="dashboard-opacity">
                        <DashboardNavigation dashboard={this.props.dashboard} hideProfileIcon={true} />
                        <div className="dashboard-header">
                            {this.props.dashboard.title}
                        </div>
                    </div>
                    <div className="dashboard-items">
                        {
                            this.props.dashboard.sections.map((item) => (
                                
                                !item.hidden_on_main && <Link to={item.path ? `${this.props.match.url}/${item.path}` : '#'} key={item.title}>
                                    <div className={`dashboard-item ${!item.enabled ? 'dashboard-item-disabled' : 'dashboard-item-' + item.style}`}>
                                        <div className={`dashboard-item-title ${!item.enabled ? 'dashboard-item-title-disabled' : 'dashboard-item-title-' + item.style}`}>{item.title}</div>
                                        <div className={`dashboard-item-subtitle ${!item.enabled ? 'dashboard-item-subtitle-disabled' : 'dashboard-item-title-' + item.style}`}>{item.subtitle}</div>
                                    </div>
                                </Link>

                            ))
                        }
                    </div>
                </div>
                {
                    showAppointmentRequest && <Popup>
                            <WarningFirstAppointment onConfirm={this.handleConfirmWarning} message={showAppointmentRequestMessage}/>
                        </Popup>
                }
            </div>
        );
    }
}

export default withRouter(DashboardMain);