import React from 'react';
import './TreatmentSelection.css';
import { Next, BackButtonHeader } from '../Navigation';
import { createMarkup } from '../Helpers';
import { Link } from 'react-router-dom';
import Payment from '../Payment';
import authClient from "../Auth";
import { selectTreatment } from "../api";
import LoadingPopup from '../Common/LoadingPopup';

class TreatmentSelection extends React.Component {

    constructor({ match, history }) {
        super();
        const user = authClient.getUserData();
        this.treatmentSelection = user.treatment_selection;
        this.urlPrefix = '/complete-profile/treatment';
        this.lastStepUrl = 'pay';
        this.makeNextUrl = this.makeNextUrl.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.state = {
            selectedTreatment: Number(match.params.id),
            selectedStep: Number(match.params.stepId),
            choices: this.treatmentSelection.choices,
            history: history,
            isLastStep: match.params.stepId === this.lastStepUrl,
            paymentClicked: false,
            paymentUrl: ''
        };
    }

    makeNextUrl() {
        console.log(`Building url with treatment: ${this.state.selectedTreatment} step: ${this.state.selectedStep}`);
        const treatment = this.treatmentSelection.choices[this.state.selectedTreatment]
        const count = treatment.steps.length;
        const currentStep = this.state.selectedStep || 0;
        let next = currentStep + 1;
        if (currentStep === count - 1) {
            next = this.lastStepUrl;
        }
        return `${this.urlPrefix}/${this.state.selectedTreatment}/step/${next}`;
    }

    componentWillReceiveProps(props) {
        let isLastStep = false;
        const selectedTreatment = Number(props.match.params.id);
        const stepId = props.match.params.stepId;
        if (stepId === this.lastStepUrl) {
            isLastStep = true;
        }
        const selectedStep = Number(props.match.params.stepId) || 0;
        if (selectedTreatment >= 0) {
            const currentTreatment = this.state.choices[selectedTreatment];
            const steps = currentTreatment.steps;
            if (selectedStep === steps.length) {
                isLastStep = true;
            }
        }
        console.log('Setting state:', selectedTreatment, selectedStep, isLastStep);
        this.setState(state => ({
            selectedTreatment: selectedTreatment,
            selectedStep: selectedStep,
            isLastStep: isLastStep
        }));
    }

    handlePayment() {
        const treatment = this.treatmentSelection.choices[this.state.selectedTreatment];
        const treatmentId = treatment.id;
        this.setState({ loading: true }, () => {
            selectTreatment(treatmentId)
            .then((response) => {
                window.location = response.init_point;
            }).catch((error) => { 
                this.setState({ loading: false });
            });
        });
    }

    render() {
        let component;
        let title;
        let gradientStyle = 'red';
        if (this.state.selectedTreatment >= 0) {
            const currentTreatment = this.state.choices[this.state.selectedTreatment];
            gradientStyle = currentTreatment.style;
            if (this.state.isLastStep) {
                const product = currentTreatment.product || {};
                title = this.treatmentSelection.payment_section_title;
                component = <Payment styleName={gradientStyle} amount={product.unit_price} description={product.description} onPayPressed={this.handlePayment} showWarning={true} history={this.props.history} />;
            } else  {
                const steps = currentTreatment.steps;
                const selectedStep = this.state.selectedStep || 0;
                const currentStep = steps[selectedStep];
                if (currentStep) {
                    title = currentStep.title;
                    
                    component = (
                        <div>
                            <div className="treatment-markup-container"
                                dangerouslySetInnerHTML={createMarkup(currentStep.content)}>
                            </div>
                            <Link className="treatment-step-next" to={this.makeNextUrl()}>
                                <Next styleName={gradientStyle} />
                            </Link>
                        </div>
                    );
                }
            }
        } else {
            title = this.treatmentSelection.title;
            component = (
                this.state.choices.map((choice, index) => {
                    return <div key={choice.id} className="treatment-choice">
                        <span>{choice.name}</span>
                        <Link className="treatment-selection-link" to={`/complete-profile/treatment/${index}/step/0`}>
                            <img src={`${process.env.PUBLIC_URL}/img/treatment/${choice.style}-icon.svg`} alt={choice.name} />
                        </Link>
                    </div>
                })
            );
        }
        return (
            <div className="treatment-selection-container">
                <div className={`treatment-selection-gradient-div treatment-selection-gradient-div-${gradientStyle}`}>
                    <div className="treatment-selection-main">
                        <BackButtonHeader title={title} goBack={this.state.history.goBack} />
                        <div className="treatment-selection-content">
                            {component}
                        </div>

                    </div>
                </div>
                { this.state.loading && <LoadingPopup /> }
            </div>
        );
    }
}

export default TreatmentSelection;